<template>
  <v-dialog v-model="value" persistent scrollable max-width="90vw">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ newPromotion ? 'New Promotion' : promotion.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <select-artist @onChange="handleArtistChange" :useActiveArtist="false"></select-artist>
        <v-select class="mb-4" :items="promotionTypes" label="Promotion Type" v-model="promotion.type" itemText="text"
          itemValue="value" hide-details :rules=[rules.required]></v-select>
        <v-text-field class="mb-4" v-if="promotion.payload.artist" label="Title" v-model="promotion.title"
          :rules=[rules.required]></v-text-field>
    <draggable :list="promotion.payload.html" class="d-flex flex-wrap" @end="onEnd">
        <v-card width="100%">
          <v-card-title class="pa-0 elevation-0">
            <v-toolbar color="primary" dense dark>
              <v-toolbar-title>HTML Header Block</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="show1 = ! show1">
                <v-icon>mdi-drag</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text v-if="show1">
            <vue-editor useCustomImageHandler @image-added="handleImageAdded"
              v-model="promotion.payload.html.header">
            </vue-editor>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <h2>Use this section to add information about artist artworks if appropriate</h2>
            <vue-editor v-model="promotion.payload.html.artworks"></vue-editor>
            <v-autocomplete :rules=[rules.imagesRequired] multiple v-if="promotion.payload.artist" :items="artworks"
              label="Select Artworks" v-model="promotion.payload.artworks" hide-selected return-object item-text="title"
              item-value="file">
              <template v-slot:selection="{ item, index }">
                <div class="d-flex align-center my-2 py-2">
                  <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.title
                    }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center py-2">
                  <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.title
                    }}</span>
                </div>
              </template>
            </v-autocomplete>
            <draggable v-if="promotion.payload.artworks" :list="promotion.payload.artworks" class="d-flex flex-wrap"
              @end="onEnd">
              <v-card v-for="(image, index) in promotion.payload.artworks" :key="image.file" class="pa-4 ma-2">
                <v-icon style="position: absolute; top: 0; right: 0;" @click="onRemove(index)">mdi-delete</v-icon>
                <v-img width="150" height="150" contain class="ma-2" :src="image.imgUrl" ripple />
              </v-card>
            </draggable>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <h2>Use this section to add information about artist products if appropriate</h2>
            <vue-editor v-model="promotion.payload.html.products"></vue-editor>
            <v-autocomplete :rules=[rules.imagesRequired] multiple :items="products"
              label="Select Products" v-model="promotion.payload.products" hide-selected return-object item-text="label"
              item-value="file">
              <template v-slot:selection="{ item, index }">
                <div class="d-flex align-center my-2 py-2">
                  <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.label
                    }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center py-2">
                  <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.label
                    }}</span>
                </div>
              </template>
            </v-autocomplete>
            <draggable v-if="promotion.payload.products" :list="promotion.payload.products" class="d-flex flex-wrap"
              @end="onEnd">
              <v-card v-for="(image, index) in promotion.payload.products" :key="image.title" class="pa-4 ma-2">
                <v-icon style="position: absolute; top: 0; right: 0;" @click="onRemove(index)">mdi-delete</v-icon>
                <v-img width="150" height="150" contain class="ma-2" :src="image.imgUrl" ripple />
              </v-card>
            </draggable>
          </v-card-text>
        </v-card>
    </draggable>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="mr-4" @click.native="savePromotion(true)">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ArtistApi from '@/api/admin/artist'
import PromotionApi from '@/api/promotion'
import ProductApi from '@/api/admin/product'
import draggable from 'vuedraggable'
import { VueEditor } from "vue2-editor";
import { mapGetters } from 'vuex'
import SelectArtist from '@/components/SelectArtist'
import axios from 'axios'

export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    VueEditor,
    draggable,
    SelectArtist
  },
  data() {
    return {
      show1: true,
      artists: [],
      artworks: [],
      products: [],
      newPromotion: true,
      promotion: this.data || { payload: { html: {}, blocks: [], artworks: [], artist: null, products: [] }, title: null, type: null },
      promotionTypes: [
        { text: 'New Artist', value: 'NEW_ARTIST' },
        { text: 'New Artworks', value: 'NEW_ARTIST_IMAGES' },
        { text: 'New Products', value: 'NEW_ARTIST_PRODUCTS' },
      ],
      rules: {
        imagesRequired: () => {
          return this.promotion.payload.artworks?.length > 3 || 'At least 4 images are required for this promotion template'
        },
        required: (value) => !!value || 'Required.'
      },
      valid: true,
      processing: false,
      promotionType: null,
      mode: 'preview'
    }
  },
  async created() {
    console.log(this.data)
    if (this.data) {
      this.handleArtistChange(this.data.payload.artist)
      this.newPromotion = false
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {},
  methods: {
    onEnd() {
    },
    onRemove(img) {
      this.promotion.payload.artworks.splice(img, 1)
    },
    close() {
      this.$emit('close')
    },
    handleArtistChange(artist) {
      ArtistApi.loadArtist(artist.artistCode).then((results) => {
        this.promotion.payload.artist = results.data.artist
        this.artworks = results.data.artworks.map(artwork => {
          return {
            imgUrl: `${process.env.VUE_APP_IMG_URL}/300px/${artwork.artworkImage}`,
            title: artwork.title,
            region: artwork.region,
            subjectMatter: artwork.subjectMatter,
            file: artwork.artworkImage,
            products: artwork.products
          }
        })
        this.products = results.data.products.map(product => {
          return {
            imgUrl: `${process.env.VUE_APP_IMG_URL}/300px/${product.variants[0].image}`,
            title: product.title,
            file: product.variants[0].image,
            label: `${product.category} - ${product.title}`,
            category: product.category,
          }
        })
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    savePromotion(close) {
      this.updating = true
      const rest = this.newPromotion ? 'createPromotion' : 'updatePromotion'
      PromotionApi[rest](this.promotion).then((results) => {
        // this.promotion = {...results.data }
        this.updating = false
      }).catch(
        err => {
          console.log('err', err)
          this.updating = false
        }
      )
      this.isDirty = false
      if (close) { this.close() }
    },
    handleImageAdded(file, Editor, cursorLocation) {
      const CLIENT_ID = '993793b1d8d3e2e'

      var formData = new FormData();
      formData.append('image', file)

      axios({
        url: 'https://api.imgur.com/3/image',
        method: 'POST',
        headers: {
          'Authorization': 'Client-ID ' + CLIENT_ID
        },
        data: formData
      })
        .then((result) => {
          console.log(result);
          let url = result.data.data.link
          Editor.insertEmbed(cursorLocation, 'image', url);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep p {
  margin-bottom: 1em !important;
}
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>
